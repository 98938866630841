.dashboardBody{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

@media screen and (max-width : 1200px){
    .dashboardBody{
        justify-content: flex-start;
        margin-top: 2rem;
    }
}

@media screen and  (max-width:768px){
 .dashboardBody{
    align-items: center;
 }   
}